import { subscribeKey } from "valtio/utils";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_NODE, IS_TESTING } from "@/__main__/constants.mjs";
import featureFlags, { allowRead } from "@/__main__/feature-flags.mjs";
import blitzMessage, { EVENTS, initEvents } from "@/__main__/ipc-core.mjs";
import mainRefs from "@/__main__/refs.mjs";
import router from "@/__main__/router.mjs";
// import { setFeatureFlag } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { HUB_SYMBOL_HOME } from "@/app/constants.mjs";
import appRefs from "@/app/refs.mjs";
import AdWrapper from "@/feature-ads/AdWrapper.jsx";
import {
  cssAdStyles,
  cssIframeEvents,
} from "@/feature-ads/constants/constants.mjs";
import {
  CONFIG as DISPLAY_CONFIG,
  subscribeToRouteChangeEvents,
} from "@/feature-ads/display-server-google.mjs";
import { setupAditude } from "@/feature-ads/display-vendor-aditude.mjs";
import fetchRemoteAdConfig from "@/feature-ads/fetch-remote-ad-config.mjs";
import {
  setupGlobalDisplayAd,
  teardownDisplayProvider,
} from "@/feature-ads/global-display-ad.mjs";
import { CONFIG as VIDEO_CONFIG } from "@/feature-ads/global-video-ad.mjs";
import HeaderWrapper from "@/feature-ads/HeaderWrapper.jsx";
import {
  externalContainerCss,
  titleBarCss,
} from "@/feature-ads/HeaderWrapper.style.jsx";
import adsRefs from "@/feature-ads/refs.mjs";
import { appRoutes as marketingAppRoutes } from "@/routes/marketing.mjs";
import matchRefs from "@/shared/Match.refs.jsx";
import profileRefs from "@/shared/Profile.refs.jsx";
import { devError } from "@/util/dev.mjs";
import exitRefs from "@/util/exit-transitions.refs.mjs";
import globals from "@/util/global-whitelist.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";
import nextFrame from "@/util/next-frame.mjs";

adsRefs.shouldShowAds.push(() => {
  const { route } = router;
  if (!route) return false;

  return (
    route.hubSymbol !== HUB_SYMBOL_HOME &&
    !route.currentPath.startsWith("/getting-started") &&
    !route.currentPath.includes("/patchnotes") &&
    !route.currentPath.includes("/season-review") &&
    !route.currentPath.startsWith("/tft/set-") &&
    !route.currentPath.startsWith("/lol/champions") &&
    !route.currentPath.startsWith("/palworld/database") &&
    !route.currentPath.startsWith("/tft/traits") &&
    // Dirty hack to prevent ads from showing on the "coming soon" pages
    !marketingAppRoutes.find((r) => r.component === route.component)
  );
});

adsRefs.waitForAdsConfirmation = async (): Promise<void> => {
  const waitAdsStatusFromUserRole: Promise<void> = new Promise((resolve) => {
    // Never resolve on Node.
    if (IS_NODE) return;

    if (readState.volatile.shouldHaveAds) {
      resolve();
      return;
    }

    eventBus.once("shouldHaveAds", (value) => {
      if (value) {
        resolve();
      }
    });
  });

  const waitAdsStatusFromCMS: Promise<void> = new Promise((resolve) => {
    allowRead();
    if (IS_NODE || featureFlags.adsEnable) {
      resolve();
      return;
    }
    appRefs.fetchedFeatureFlags.then(() => {
      allowRead();
      if (featureFlags.adsEnable === false) {
        // setFeatureFlag("ads", false);
      }
      // make sure dont pending here
      resolve();
    });
  });

  await Promise.all([waitAdsStatusFromUserRole, waitAdsStatusFromCMS]);
  return Promise.resolve();
};

const original = mapOriginalRefs({
  mainRefs,
  appShellRefs: appRefs.appShell,
  mainRefComponents: mainRefs.components,
  matchRefs,
  profileRefs,
});

const cleanup = {
  unsubscribeRouteEvents: null as (() => void) | null,
  remoteCfgCleanup: null as (() => void)[] | null,
  removeGlobalStyle: null as (() => void) | null,
};

const setupTeardownTuples = [
  [setupStaticData, teardownStaticData],
  [setupDisplayAds, teardownDisplayAds],
  [setupIframeEvents, teardownIframeEvents],
] as const;

/**
 * This isn't strictly necessary, but it's a safety feature in case
 * ads are enabled in a context where they really shouldn't be.
 */
function shouldEnableAds() {
  if (IS_TESTING) return true;
  // This is disabled so that we render the placeholders on SSR.
  // if (IS_NODE) return false;
  return true;
}

export function setup() {
  if (!shouldEnableAds()) return;
  eventBus.emit("adsStatusChange", true);

  for (const [setup] of setupTeardownTuples) {
    try {
      setup();
    } catch (e) {
      devError("Failed to initialize ads!", e);
    }
  }

  // Why disable background throttling if ads is enabled?
  //
  // Ads can call setInterval and requestAnimationFrame, which will have
  // adverse effects when resuming the render thread, it could block
  // the thread upon resuming, by running queued up functions while the
  // thread was inactive.
  (async function () {
    await initEvents;
    return blitzMessage(EVENTS.SET_BACKGROUND_THROTTLE, false);
  })();

  exitRefs.shouldTransition = false;
  nextFrame(() => {
    exitRefs.shouldTransition = true;
  });
}
export function teardown() {
  if (!shouldEnableAds()) return;
  eventBus.emit("adsStatusChange", false);

  // Safety feature: do not disable ads on the same route, only disable
  // on route change. We don't want there to be any possibility of janky
  // behavior where ads are toggled on/off quickly because that may hurt
  // viewability.

  // Hopefully this shouldn't be needed anymore...
  // await routeChange();

  for (const [, teardown] of setupTeardownTuples) {
    teardown?.();
  }

  (async function () {
    await initEvents;
    return blitzMessage(EVENTS.SET_BACKGROUND_THROTTLE, true);
  })();

  exitRefs.shouldTransition = false;
  nextFrame(() => {
    exitRefs.shouldTransition = true;
  });
}

/*function routeChange() {
  return new Promise((resolve) => {
  const previousPath = router.route?.currentPath;
  router.events.on(EVENT_CHANGE_ROUTE, listener);
  function listener({ currentPath }) {
  if (currentPath === previousPath) return;
  router.events.off(EVENT_CHANGE_ROUTE, listener);
  resolve();
  }
  });
  }*/

function setupDisplayAds() {
  // assume that teardown() may be called before this fetch resolves
  // so we must make sure of 2 things:
  // 1. this function does not block setting the below refs
  // 2. the teardown function is idempotent
  fetchRemoteAdConfig().then((config) => {
    if (!config.enabled) return teardownDisplayAds();

    // must call setupAditude for refs first!
    setupAditude(false);
    setupGlobalDisplayAd();
  });

  original.set({
    mainRefComponents: {
      HeaderWrapper: HeaderWrapper,
      ContentWrapper: AdWrapper,
    },
  });

  original.append({
    appShellRefs: {
      titleBarClasses: [titleBarCss],
    },
    mainRefs: {
      externalContainerClasses: [externalContainerCss],
      internalContainerClasses: [cssAdStyles, IS_APP ? cssIframeEvents : null],
    },
  });

  cleanup.unsubscribeRouteEvents = subscribeToRouteChangeEvents();

  if (!IS_NODE) {
    const globalStyleElement = globals.document.createElement("style");
    globalStyleElement.innerHTML = `
      body > iframe[width="0"][height="0"] {
        display: none;
      }
    `;
    globals.document.head.appendChild(globalStyleElement);

    cleanup.removeGlobalStyle = () => {
      globalStyleElement.remove();
    };
  }
}

function setupStaticData() {
  if (IS_TESTING) return; // TODO: write tests
  cleanup.remoteCfgCleanup = [
    subscribeKey(
      readState.volatile,
      "adsRemoteConfig",
      /**  @param {import("@/feature-ads/data-model-ads-static.mjs").AdsConfig} config */
      (config) => {
        if (!config) return;
        if (config.refresh) DISPLAY_CONFIG.REFRESH_MS = config.refresh * 1000;
        if (config.mod) DISPLAY_CONFIG.REFRESH_MOD = config.mod;
        if (config.maxRefreshCount)
          DISPLAY_CONFIG.ADS_REFRESH_COUNT_MAX = config.maxRefreshCount;

        // video
        const { videoRefresh } = config;
        if (videoRefresh) VIDEO_CONFIG.REFRESH_MS = videoRefresh * 1000;
        VIDEO_CONFIG.SHOULD_REFRESH = videoRefresh > 0;
      },
    ),
  ];
  // run once to initialize the changes above
  fetchRemoteAdConfig();
}

function teardownStaticData() {
  cleanup.remoteCfgCleanup?.forEach((fn) => fn());
}

function teardownDisplayAds() {
  original.restore();

  teardownDisplayProvider();
  cleanup.unsubscribeRouteEvents?.();
  cleanup.removeGlobalStyle?.();
}

let unsubscribeFocus = null;
function setupIframeEvents() {
  const focusClass = "is-focused";
  mainRefs.internalContainerClasses.add(focusClass);
  unsubscribeFocus = subscribeKey(readState.volatile, "isFocused", (value) => {
    if (value) {
      mainRefs.internalContainerClasses.add(focusClass);
    } else {
      mainRefs.internalContainerClasses.delete(focusClass);
    }
  });
}

function teardownIframeEvents() {
  unsubscribeFocus();
}
